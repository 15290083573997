
$fa-font-path: '../fonts';
$fa-font-display: 'swap';

// Traido de node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss
@import 'node_modules/@fortawesome/fontawesome-free/scss/variables';
@import 'node_modules/@fortawesome/fontawesome-free/scss/mixins';
@import 'node_modules/@fortawesome/fontawesome-free/scss/core';
@import 'node_modules/@fortawesome/fontawesome-free/scss/larger';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fixed-width';
@import 'node_modules/@fortawesome/fontawesome-free/scss/list';
@import 'node_modules/@fortawesome/fontawesome-free/scss/bordered-pulled';
@import 'node_modules/@fortawesome/fontawesome-free/scss/animated';
@import 'node_modules/@fortawesome/fontawesome-free/scss/rotated-flipped';
@import 'node_modules/@fortawesome/fontawesome-free/scss/stacked';
@import 'node_modules/@fortawesome/fontawesome-free/scss/icons';
@import 'node_modules/@fortawesome/fontawesome-free/scss/screen-reader';


// Traido de node_modules/@fortawesome/fontawesome-free/scss/regular.scss
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-regular-400.eot');
  src: url('#{$fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
  url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'FontAwesome';
  font-weight: 400;
  font-display: $fa-font-display;
}

// Traido de node_modules/@fortawesome/fontawesome-free/scss/solid.scss
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
  url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'FontAwesome';
  font-weight: 900;
  font-display: $fa-font-display;
}

// Traido de node_modules/@fortawesome/fontawesome-free/scss/brands.scss
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-brands-400.eot');
  src: url('#{$fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
  url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'FontAwesome';
  font-display: $fa-font-display;
}


// Traido de node_modules/@fortawesome/fontawesome-free/scss/v4-shims.scss
@import 'node_modules/@fortawesome/fontawesome-free/scss/shims';
